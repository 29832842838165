/* Estilos generales para la página de contacto */
.contact-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    min-height: 100vh;
  }
  
  .contact-header {
    background-color: #4c6675; /* Azul oscuro */
    color: white;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
  }
  
  .contact-header h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .contact-section {
    margin-top: 40px;
  }
  
  .contact-form {
    background-color: #ffffff; /* Fondo blanco */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 16px;
    color: #4c6675; /* Azul oscuro */
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #83b993; /* Borde verde azulado */
    border-radius: 5px;
    font-size: 16px;
    color: #111e2a; /* Texto oscuro */
  }
  
  .form-group textarea {
    height: 100px;
    resize: none;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .send-btn {
    background-color: #83b993; /* Verde azulado */
    color: #111e2a;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-btn:hover {
    background-color: #111e2a; /* Azul oscuro */
    color: white;
  }
  
  .whatsapp-btn {
    background-color: #25D366; /* Color de WhatsApp */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-btn:hover {
    background-color: #128C7E; /* Oscurecimiento del color de WhatsApp */
  }
  
  .contact-footer {
    background-color: #4c6675; /* Azul oscuro */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    border-radius: 8px;
  }
  
  .contact-footer p {
    margin: 0;
  }
  
  /* Estilos responsive */
  @media (max-width: 768px) {
    .button-group {
      flex-direction: column;
      gap: 10px;
    }
  
    .send-btn, .whatsapp-btn {
      width: 100%;
      text-align: center;
    }
  }
  