/* Estilos generales para el componente del botón */
.home-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2e4a52; /* Fondo oscuro */
    padding: 20px;
    border-radius: 15px;
    width: 250px;
    margin: 0px auto;
    color: white;
  }
  
  .home-title {
    font-size: 24px;
    margin: 0;
    color: #fff;
  }
  
  .button-content {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .icon-circle {
    width: 40px;
    height: 40px;
    background-color: #111e2a; /* Verde claro */
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .home-btn {
    background-color: #83b993; 
    color: #111e2a;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  .home-btn:hover {
    background-color: #83b993; /* Verde azulado del logo */
  }
  
  .home-description {
    font-size: 14px;
    color: #d3d3d3; /* Texto en gris claro */
    text-align: center;
    margin-top: 10px;
  }
  