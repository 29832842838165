/* Estilos para la caja de contenido */
.content-box {
    background-color: #ffffff; /* Fondo blanco */
    border: 2px solid #2aa198; /* Borde verde azulado */
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #002b36; /* Azul oscuro para el texto */
  }
  
  .content-box h2 {
    font-size: 24px;
    color: #073642; /* Color para el título */
    margin-bottom: 10px;
  }
  
  .content-box p {
    font-size: 16px;
    line-height: 1.6;
    color: #586e75; /* Color para el texto */
  }
  