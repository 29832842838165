/* Estilos generales para la página de servicios */
.services-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #111e2a; /* Fondo claro */
    color: #f1f4f6; /* Azul oscuro */
    min-height: 100vh;
  }
  
  .services-header {
    background-color: #4c6675; /* Azul oscuro */
    color: white;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
  }
  
  .services-header h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .services-section {
    margin-top: 40px;
  }
  
  .services-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .services-section li {
    font-size: 18px;
    margin: 10px 0;
    color: #111e2a;
    padding: 10px;
    background-color: #ffffff; /* Fondo blanco */
    border-left: 5px solid #83b993; /* Borde verde */
    border-radius: 5px;
  }
  
  .services-footer {
    background-color: #4c6675; /* Azul oscuro */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    border-radius: 8px;
  }
  
  .services-footer p {
    margin: 0;
  }
  
  /* Estilos responsive */
  @media (max-width: 768px) {
    .services-header {
      padding: 20px;
    }
  
    .services-header h1 {
      font-size: 28px;
    }
  
    .services-section li {
      font-size: 16px;
    }
  }
  