/* Estilos para la página About */
.about-container {
    background-color: #fdf6e3; /* Fondo claro para la sección */
    color: #002b36; /* Azul oscuro para el texto */
    padding: 40px;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-container h1 {
    font-size: 36px;
    color: #073642; /* Azul más claro para el título */
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 18px;
    line-height: 1.6;
    color: #586e75; /* Color gris oscuro para el texto */
  }
  
  