/* Estilos generales para la página de inicio */
.home-container {
    font-family: Arial, sans-serif;
    background-color: #4c6675; /* Azul oscuro del header */
    color: #111e2a; /* Azul oscuro similar al header */
  }
  
  .home-header {
    background-color: #4c6675; /* Azul oscuro del header */
    color: #ffffff; /* Texto blanco */
    text-align: center;
    display: flex;
  }
  

    
  .home-header img {
    width:480px;
  }
  

  .home-header h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .home-header p {
    font-size: 18px;
  }
  
  .section {
    background-color: #111e2a; /* Azul oscuro inspirado en el logo */
    color: #4c6675;
    padding: 20px 0;
  }
  

  
  .section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .section li {
    font-size: 18px;
    margin: 10px 0;
    color: #111e2a;
    padding: 10px;
    background-color: #ffffff; /* Fondo blanco para cada servicio */
    border-left: 5px solid #83b993; /* Borde verde azulado */
    border-radius: 5px;
  }
  
  .home-footer {
    background-color: #111e2a; /* Azul oscuro inspirado en el logo */
    color: #4c6675;
    text-align: center;
    padding: 20px;
  }
  
  .home-footer p {
    margin: 0;
  }
  
  /* Estilos responsive para la página de inicio */
  @media (max-width: 768px) {
    .home-header {
        justify-content: center;
    }

    .home-header img {
        width:360px;
      }
  
    .services-section h2 {
      font-size: 24px;
    }
  
    .services-section li {
      font-size: 16px;
    }
  }
  