/* Estilos generales para el header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #111e2a; /* Azul oscuro inspirado en el logo */
    color: #4c6675;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    padding: 10px 60px;
  }

  .logo h1 {
    color: #ffffff;
    font-size: 24px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
  }
  
  .nav-links li {
    display: inline-block;
  }
  
  .nav-links a {
    color: #4c6675;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  .nav-links a:hover {
    background-color: #111e2a; /* Verde azulado del logo */
    color: #ffffff;
  }
  
  .contact-button .btn {
    background-color: #83b993; 
    color: #111e2a;
    padding: 5px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .contact-button .btn:hover {
    background-color: #111e2a; /* Verde azulado del logo */
    color: #ffffff;
  }
  
  /* Estilos responsive para dispositivos móviles */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .header {
      justify-content: space-between;
    }
  }
  
  @media (min-width: 768px) {
    .nav-links {
      display: flex;
    }
  }
  